var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('PageTitle',{staticClass:" font d-flex flex-row ml-2",attrs:{"text":"Customers"}}),_c('div',[_c('v-text-field',{staticClass:"mt-2 font font-size-sm black--text",staticStyle:{"min-width":"500px"},attrs:{"dense":"","append-icon":"search","light":"","single-line":"","solo-inverted":"","placeholder":"Search by customer name  ...","hide-details":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"font ml-2 mt-3 white--text font-size-sm",attrs:{"color":"primary","to":{ name: 'master.customers.new' }}},[_c('span',{staticClass:" font-size-sm"},[_vm._v("Add New Customer")]),_c('v-icon',{staticClass:"font-size-sm",attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-3 ml-4",attrs:{"fab":"","icon":"","small":"","color":"primary"},on:{"click":_vm.refreshCustomerList}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Load Update")])]),_c('h4',{staticClass:" font blue-grey--text",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List Of Business Customers ")])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"box-shadow-light  font font-weight-medium",attrs:{"items":_vm.filteredBusinesses,"loading":_vm.isListLoading,"server-items-length":_vm.paginate.businesses.total,"search":_vm.search,"options":_vm.options.businesses,"headers":_vm.headers.business.filter(function (title) { return title.value !== 'category'; }),"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "businesses", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.directors",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.directors.length))])])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:" font font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.amount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" )))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","disabled":_vm.dialog('edit'),"fab":"","color":"primary","icon":""},on:{"click":function($event){return _vm.open('edit', 'customers/details', {
                    id: item.id,
                    type: 'customer-business'
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("Edit Customer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":item.form3Upload === null,"color":"primary","icon":""},on:{"click":function($event){return _vm.openPDF(item)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("picture_as_pdf")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View attached docs")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details'),"color":"primary","icon":""},on:{"click":function($event){return _vm.open('details', 'customers/details', {
                    id: item.id,
                    type: 'customer-business'
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("assignment")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View Customer Details")])])]}}])})],1)],1),_c('div',[_c('h4',{staticClass:" font blue-grey--text",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List Of Individual Customers ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"font font-weight-medium box-shadow-light",attrs:{"items":_vm.filteredIndividuals,"loading":_vm.isListLoading,"headers":_vm.headers.individuals,"server-items-length":_vm.paginate.individuals.total,"search":_vm.search,"options":_vm.options.individuals,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "individuals", $event)}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:" font font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.amount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" )))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details'),"color":"primary","icon":""},on:{"click":function($event){return _vm.open('add', 'customers/details', {
                    id: item.id,
                    type: 'customers'
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Edit Customer")])])]}}])})],1)],1),_c('div',[_c('h4',{staticClass:" font blue-grey--text",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List Of Suppliers ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"font font-weight-medium box-shadow-light",attrs:{"items":_vm.filteredSuppliers,"loading":_vm.isListLoading,"headers":_vm.headers.business.filter(
            function (header) { return header.value !== 'directors' &&
              header.value !== 'amount' &&
              header.value !== 'actions'; }
          ).concat( [{ text: 'Credit Purchases Amt.', value: 'creditPurchasesAmount' }],
          [{ text: 'Actions.', value: 'actions' }]
        ),"server-items-length":_vm.supplierPaginate.total,"search":_vm.search,"options":_vm.options.suppliers,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){return _vm.$set(_vm.options, "suppliers", $event)}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.category ? item.category : "N/A"))])])]}},{key:"item.creditPurchasesAmount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_c('span',{staticClass:" font font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.creditPurchasesAmount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" )))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer",attrs:{"small":"","label":""},on:{"click":function($event){return _vm.openMail(item)}}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","disabled":_vm.dialog('edit'),"fab":"","color":"primary","icon":""},on:{"click":function($event){return _vm.open('edit', 'suppliers/details', { id: item.id })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("Edit Supplier")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":item.businessRegistrationCert === null,"color":"primary","icon":""},on:{"click":function($event){return _vm.openPDF({ form3Upload: item.businessRegistrationCert })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("picture_as_pdf")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View attached docs")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","disabled":_vm.dialog('details'),"color":"primary","icon":""},on:{"click":function($event){return _vm.open('details', 'suppliers/details', {
                    id: item.id
                  })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("assignment")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v("View Customer Details")])])]}}])})],1)],1),_c('DetailsDrawerDialog',{attrs:{"state":_vm.dialog('details'),"details":_vm.details},on:{"close":_vm.close,"openMail":_vm.openMail}}),_c('EditDetailsDrawerDialog',{attrs:{"state":_vm.dialog('edit'),"detailsLoading":_vm.isSubLoading,"loading":_vm.loading,"currency":_vm.currency,"details":_vm.details},on:{"close":_vm.close,"updateCustomer":_vm.updateCustomer}}),_c('EditIndividualCustomer',{attrs:{"details":_vm.details,"state":_vm.dialog('add'),"loading":_vm.loading,"detailsLoading":_vm.isSubLoading,"cards":_vm.cards},on:{"close":_vm.close,"editCustomer":_vm.editIndividualCustomer}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }